import React, { createContext, useContext, useState, useCallback } from 'react';

const defaultValues = {
  showProgress: true,
  showLogout: false,
  session: '',
  key: ''
}

const updateConfig = (newConf:any)=>{}
const ConfigContext = createContext({config:defaultValues,updateConfig});

export const ConfigProvider = ({ children }:any) => {
  const [config, setConfig] = useState(defaultValues);
  const updateConfig = useCallback((newConfig:any) => {
    setConfig((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);

  return (
    <ConfigContext.Provider value={{ config, updateConfig }}>
      {children}
    </ConfigContext.Provider>
  );
};

export const useConfig = () => {
  return useContext(ConfigContext);
};