import React, { createContext, useContext, useState, useCallback } from 'react';
const defaultValues = {name: "",image:""}

const updateUser = (newConf:any)=>{}
const UserContext = createContext({user:defaultValues,updateUser});


export const UserProvider = ({ children }:any) => {
  const [user, setUser] = useState(defaultValues);
  const updateUser = useCallback((newConfig:any) => {
    setUser((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);

  return (
    <UserContext.Provider value={{ user, updateUser }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => {
  return useContext(UserContext);
};