import { Modal } from "antd";
import { useConfig } from "../contexts/ConfigContext";
import React, { useState } from "react";
import Request from '../services/fetchDataService'

function LogoutModal(){
  const {config,updateConfig} = useConfig()
  const [loading, setLoading] = useState(false)
  
  const handleClose = () => updateConfig({showLogout: false})
  const handleLogout = () => {
    setLoading(true)
    Request.post('/security/auth/logout',{},config.key)
    .then((res:any) => {
      setLoading(false)
      if(res.redirect) window.location.href = res.redirect
    }
    ).catch((err:any) => window.location.href = 'error')
  }
  return (
    <Modal 
      title={<div style={{ textAlign: "center",fontSize:"1.3rem" }}>Confirma la decisión</div>}
      open={config.showLogout} 
      confirmLoading={loading}
      maskClosable={false} className="modal-responsive-md"
      onOk={handleLogout} onCancel={handleClose}>
      <p style={{marginTop:"1rem"}}>Al terminar tu sesión, se perderán los cambios que no hayas guardado</p>
    </Modal>
  )
}

export default LogoutModal