import { useCompany } from "../contexts/CompanyContext"
import { useUser } from "../contexts/UserContext"
import { useConfig } from "../contexts/ConfigContext"
import React from "react"
import { Header } from "antd/es/layout/layout"
import { Avatar, Dropdown, MenuProps, Space } from "antd"
import { PoweroffOutlined, SettingOutlined, UnorderedListOutlined, UserOutlined } from "@ant-design/icons"
import LogoutModal from "./LogoutModal"

function TopBar({mobileView}:any){

  const {companies, updateCompanies} = useCompany()
  const {updateConfig} = useConfig()
  const {user} = useUser()
  
  const onUserItem: MenuProps['onClick'] = ({ key,keyPath }) => {
    if(key === "5"){
      updateConfig({showLogout:true})
    }else{
      alert("Modulo en construccion...")
    }
  };

  const onCompanyItem: MenuProps['onClick'] = ({ key }) => {
    const selectedCompany = companies.list.find((__,index) => index.toString() === key)
    console.log("Change company",selectedCompany)
    if(selectedCompany){
      updateCompanies({current: selectedCompany})
    }
  };

  const itemsUser: MenuProps['items'] = [
    {
      key: '1',
      label: 'Mi usuario',
      disabled: true,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: 'Perfil',
      extra: '⌘P',
      icon: <UserOutlined/>
    },
    {
      key: '3',
      label: 'Configuraciones',
      icon: <SettingOutlined />,
      extra: '⌘C',
    },
    {
      key: '4',
      label: 'Registro de actividad',
      icon: <UnorderedListOutlined />,
      extra: '⌘R',
    },
    {
      type: 'divider',
    },
    {
      key: '5',
      label: 'salir',
      icon: <PoweroffOutlined />,
      extra: '⌘S',
    },
  ];
  const itemsCompany: MenuProps['items'] = companies.list.map((e,i)=>{
    return{
      key: i.toString(),
      label: e.name,
      icon: <Avatar src={e.icon}/> 
    }
  })

  itemsCompany.unshift(...[{
    key:"100",
    label: "Mis cuentas",
    disabled:true
  },{type: 'divider' as 'divider',}])

  //console.log('Load Top ',data)
  return (
    <Header style={{ 
      padding: "0 16px", background: "#fff", display: "flex", 
      alignItems: "center", justifyContent: "space-between",marginBottom:"1.5rem",
      boxShadow:"0 .15rem 1.75rem 0 rgba(58,59,69,.15)!important" }}>
      {/* Parte izquierda del Header */}
      <div style={{ display: "flex", alignItems: "center" }}>
        {/* Logo o Imagen */}
        <Avatar src={companies.current.icon} style={{ marginLeft: 5 }} />
        {!mobileView && <h3 style={{ marginLeft: 30,color:"#5a5c69",fontSize:"1.75rem" }}>{companies.current.name}</h3>}
      </div>

      {/* Parte derecha del Header */}
      <Space size="middle" style={{ display: "flex", alignItems: "center"}}>
        <div className="icon-continer-top">
          <Dropdown menu={{ items:itemsCompany,onClick:onCompanyItem }} trigger={['click']}>
            <a href="/logo/portafolio" onClick={(e) => e.preventDefault()}>
              <Space >
                <img alt="Logo portafolio" style={{width:25,paddingTop:24}} src="assets/images/briefcase-icon.svg"></img>
              </Space>
            </a>
          </Dropdown>
        </div>
        <div style={{
          width: 0,
          borderRight: "1px solid #e3e6f0",
          height: "calc(4.375rem - 2rem)",
        }}></div>
        {!mobileView && <span>{user.name}</span>}
        <div className="icon-continer-top">
          <Dropdown menu={{ items:itemsUser,onClick:onUserItem }} trigger={['click']}>
            <a href="/logo/usuario" onClick={(e) => e.preventDefault()}>
              <Space>
                <UserOutlined style={{color:"black"}}/>
              </Space>
            </a>
          </Dropdown>

        </div>
      </Space>
      <LogoutModal/>
    </Header>
  )
}

export default TopBar