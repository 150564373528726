import React, { useEffect, useState } from 'react'
import Footer from './Footer'
import { useUser } from '../contexts/UserContext'
import { useConfig } from '../contexts/ConfigContext'
import { useCompany } from '../contexts/CompanyContext'
import { Layout } from 'antd'
import TopBar from './TopBar'
import useAuth from '../hooks/useAuth'
import ContentLayuout from './ContentLayout'

function Index(){
  const { config,updateConfig } = useConfig()
  const { updateCompanies } = useCompany()
  const { updateUser } = useUser()
  const [mobileView, setMobileView] = useState(window.innerWidth < 768);

  const {tokenData} = useAuth.useToken('authCsrfToken',config.session,config.key)
  const {data:indexData} = useAuth.useIndex(tokenData,config.session)

  useEffect(()=>{
    console.log(indexData)
    if(indexData && indexData.companies){
      if(indexData.companies.length === 1 && indexData.companies[0].modules.length === 1){
        const redirect = indexData.companies[0].modules[0].path + '/?session=' + tokenData.session
        console.log("Redireccion a: ",redirect)
        window.location.href = redirect
        return
      }
      updateConfig({showProgress:false,session:tokenData.session})
      updateUser(indexData.user)
      updateCompanies({list:indexData.companies,current:indexData.companies[0]})
    }
    const handleResize = () => {
      const isMobile = window.innerWidth < 768;
      setMobileView(isMobile);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  },[indexData,tokenData])

  return (
    <Layout style={{ minHeight: "100vh" }}>

      {/* Contenedor Principal */}
      <Layout style={{ transition: "0.3s" }}>
        {/* Header */}
        <TopBar mobileView={mobileView}></TopBar>
        {/* Contenido */}
        <ContentLayuout />
        {/* Footer */}
        <Footer />
      </Layout>
    </Layout>
  );
}


export default Index;