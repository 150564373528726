import useSWR from 'swr'
import fetchDataService from '../services/fetchDataService'
import { generateFrontKeys } from '../services/securityService'
import { useCallback } from 'react'


function useLoadBase(token:any,session:any){
  const config = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
  }
  
  const fetcher = () => fetchDataService.post('/security/base/load-base',{session},token.key)  
  const {data,error} = useSWR(()=>token ? '/security/base/load-base':null,fetcher,config)

  return {
    data,
    error
  }
}

function useIndex(token:any,session:any){
  const config = {
    revalidateIfStale: false,
    revalidateOnFocus: false,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
  }
  const fetcher = () => fetchDataService.post('/security/base/index',{session},token.key)  
  const {data,error} = useSWR(()=>token  ? '/security/base/index': null,fetcher,config)

  return {
    data,
    error
  }
}

function useToken(typeToken:any,session:any,serverKey:any){
  const frontKeyFetcher = async () => {
    await generateFrontKeys
    const key = localStorage.getItem('frontKey')
    return {key}
  }
  const {data:frontKey} = useSWR('generatefrontkeys',frontKeyFetcher)

  const serverKeyFetcher = () => fetchDataService.post('/public/key',{},null)
  const tokenFetcher = (args:any) => fetchDataService.post(args[0],{
      typeToken,
      timeToken: 30,
      session
    },args[1]) 
  
  const config = {
    revalidateIfStale: true,
    revalidateOnFocus: true,
    revalidateOnReconnect: false,
    refreshWhenOffline: false,
    refreshWhenHidden: false,
    dedupingInterval: 60000
  }
  const callToServerKey = frontKey && !serverKey
  const {data:keyData,mutate: mutateKeyData} = useSWR(()=> callToServerKey ? '/public/key':null,serverKeyFetcher,config)
  const dataToInitServer = keyData ? keyData.data : serverKey
  const {data:tokenData,mutate: mutateTokenData} = useSWR(()=>dataToInitServer ? ['/security/auth/create-authsession',dataToInitServer]:null,
    tokenFetcher,
    config
  )
  if(tokenData?.status === 401){
    console.log("redireccionando a:",tokenData.redirect)
    window.location.href = tokenData.redirect
  }

  const resetState = useCallback(() => {
    mutateKeyData(undefined, { revalidate: true });
    mutateTokenData(undefined, { revalidate: true });
  }, [mutateKeyData, mutateTokenData]);

  return {
    tokenData,
    resetState
  }
}

const Auth = {
  useLoadBase,
  useIndex,
  useToken
}

export default Auth