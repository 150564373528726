import React from "react"
import { useCompany } from "../contexts/CompanyContext"
import { useConfig } from "../contexts/ConfigContext"
import { Content } from "antd/es/layout/layout"
import { Card, Col, Row } from "antd"
import Meta from "antd/es/card/Meta"

function ContentLayuout(){
  const {companies} = useCompany()
  const { config } = useConfig()
  const handleClick = (path:string) => {
    const session = config.session
    const fullPath = process.env.REACT_APP_ENV !== "production" ? path + '?session=' + session : path
    console.log(fullPath)
    window.location.href = fullPath
  }
  //console.log('Load content ',companies.current)
  return (
    <Content style={{ margin: "16px", padding: "32px", minHeight: "calc(100vh - 112px)" }}>
      <Row justify="start" gutter={[{ xs: 20, sm: 40, md: 50, lg: 32 },{ xs: 20, sm: 40, md: 50, lg: 32 }]}>
        {
          companies.current.modules.map((mod:any,index:number)=>(
            <Col key={index} xs={24} sm={12} md={8} lg={8} xl={6} style={{display:"flex",justifyContent:"center"}}>
              <Card
                hoverable
                style={{ width: "100%",maxWidth:240 }}
                cover={<img alt="example" src={`${mod.icon}`} />}
                onClick={() => handleClick(mod.path)}
              >
                <Meta title={mod.name} description={mod.description} />
              </Card>
            </Col>
          ))
        }
      </Row>
    </Content>
  )
}

export default ContentLayuout