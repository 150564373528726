import React,{useEffect, useState} from "react"
import useAuth from "./hooks/useAuth"
import { useConfig } from "./contexts/ConfigContext"
import { useHistory } from "react-router-dom"
import { Spin } from "antd"

function App(){
  const [spinning] = useState(true)
  const params = new URLSearchParams(window.location.search)
  const session = params.get('session')
  const {updateConfig} = useConfig()
  const history = useHistory()

  const {tokenData,resetState} = useAuth.useToken('authCsrfToken',session,null)
  const {data:baseData} = useAuth.useLoadBase(tokenData,session)

  useEffect(()=>{
    if(baseData){
      updateConfig({
        showProgress: false,
        key:baseData.key,
        session
      })
      //permisos insuficientes
      if(baseData.status === 401 && baseData.redirect){
        window.location.href = baseData.redirect
        return
      }
      history.push('/index')
      resetState()
    }
  },[tokenData,baseData,history,session])

  return(
    <div  style={{
      backgroundColor:"var(--light)",display:"flex",
      flexWrap:"wrap",alignItems:"center",
      justifyContent:"space-between",
      minHeight: "100vh",
      width: "100%",
    }}>
      <h1 style={{color:"#5a5c69",fontSize:"1.75rem",textAlign:"center", width:"100%" }}>Please wait for a moment...</h1> 
      <Spin size="large" tip="Cargando..." fullscreen spinning={spinning}  />
    </div>
  )
}

export default App