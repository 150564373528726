import { encryptData,decryptData } from "./securityService"

const base = "/api"
const frontKey = localStorage.getItem('frontKey')

async function post(url:any,data:any={},key:any){
  data.origin = window.location.protocol + '//' + window.location.host + window.location.pathname
  const bodyData = key ? await encryptData(data,key) : {data:{origin:data.origin},key: frontKey}
  const config:RequestInit = {
    method: 'POST',
    body: JSON.stringify(bodyData),
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    }
  }
  const res = await fetch(base + url,config)
  const encryptedData = await res.json();
  if(!encryptedData.key){
    return encryptedData
  }
  return decryptData(encryptedData)
}

const fetchDataService = {post}
export default fetchDataService