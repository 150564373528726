import React, { createContext, useContext, useState, useCallback } from 'react';
type module = {
  name:string,
  description:string,
  icon:string,
  path:string
}

type company = {
  name:string,
  modules:module[],
  icon:string
}

type TCompanyContext = {
  current:company,
  list: company[]
}
const defaultValues:TCompanyContext = {
  current: {name: '',modules:[],icon:""},
  list: []
}
let updateCompanies = (newConf:any) => {}
const CompanyContext = createContext({companies:defaultValues,updateCompanies});

export const CompanyProvider = ({ children }:any) => {
  const [companies, setCompanies] = useState(defaultValues);
  const updateCompanies = useCallback((newConfig:any) => {
    setCompanies((prevConfig) => ({ ...prevConfig, ...newConfig }));
  }, []);

  return (
    <CompanyContext.Provider value={{ companies, updateCompanies }}>
      {children}
    </CompanyContext.Provider>
  );
};

export const useCompany = () => {
  return useContext(CompanyContext);
};